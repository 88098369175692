/* Custom Fonts*/
@font-face {
  font-family: Centra;
  src: url("./assets/font/CentraNo2-Bold.ttf");
  font-weight: 700;
}
@font-face {
  font-family: Centra;
  src: url("./assets/font/CentraNo2-Medium.ttf");
  font-weight: 7500;
}
@font-face {
  font-family: Centra;
  src: url("./assets/font/CentraNo2-Book.ttf");
  font-weight: 400;
}

/* Default CSS*/
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
  scroll-padding-top: 75px;
}

body {
  font-weight: 400;
  overflow-x: hidden;
  position: relative;
  background-color: #21222A;
  color: white;
  font-family: 'Centra', sans-serif;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
  padding: 0;
  line-height: normal;
  letter-spacing: -0.05em;
}

/* Navbar */
nav.navbar {
  padding: 20px 0;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 9999;
  transition:  0.32s ease-in-out;
  background-color: #21222A;
}
nav.navbar.scrolled {
  padding: 0 0;
  background-color: #21222A;
}
nav.navbar a.navbar-brand {
    width: 9%;
}
.nav-brand-name {
    font-weight: 700;
    color: white;
    letter-spacing: -0.05em;
    font-size: 35px;
}
nav.navbar .navbar-nav .nav-link.navbar-link {
  font-weight: 400;
  color: white;
  letter-spacing: -0.05em;
  padding: 25px;
  font-size: 18px;
  opacity: 0.75;
  margin-left: 50px;
}
nav.navbar .navbar-nav a.nav-link.navbar-link:hover,
nav.navbar .navbar-nav a.nav-link.navbar-link.active {
    opacity: 1;
}
span.navbar-text {
    display: flex;
    align-items: center;
}
.typewriter h1 {
  width: auto;
  display: inline-block;
  overflow: hidden;
  border-right: .15em solid white;
  letter-spacing: .15em;
  white-space: nowrap;
  margin: 0;
  animation:
    typing 3.5s steps(45, end),
    blink-caret .75s step-end infinite;
}
@keyframes typing {
  from {max-width: 0 }
  to {max-width: 100% }
}
@keyframes blink-caret {
  from, to {border-color: transparent}
  50% {border-color: white;}
}

.social-icon {
    display: inline-block;
    margin-left: 14px;
}
.social-icon a {
    width: 42px;
    height: 42px;
    background: rgba(217, 217, 217, 0.1);
    display: inline-flex;
    border-radius: 50%;
    margin-right: 6px;
    align-items: center;
    justify-content: center;
    line-height: 1;
    border: 1px solid rgba(255, 255, 255, 0.5);
}
.social-icon a::before {
    content: "";
    width: 42px;
    height: 42px;
    position: absolute;
    background-color: white;
    border-radius: 50%;
    transform: scale(0);
	transition: 0.3s ease-in-out;
}
.social-icon a:hover::before {
    transform: scale(1);
}
.social-icon a img {
	width: 40%;
	z-index: 1;
	transition: 0.3s ease-in-out;
}
.social-icon a:hover img {
	filter: brightness(0) saturate(100%) invert(0%) sepia(7%) saturate(98%) hue-rotate(346deg) brightness(95%) contrast(86%);
}

.nav--logo {
    margin-right: 20px;
}

.navbar-text button {
  font-weight: 700;
  color: white;
  border: 1px solid white;
  padding: 18px 34px;
  font-size: 18px;
  margin-left: 18px;
	position: relative;
	background-color: transparent;
	transition: 0.3s ease-in-out;
}
.navbar-text button span {
  z-index: 1;
}
.navbar-text button::before {
  content: "";
  width: 0;
  height: 100%;
  position: absolute;
  background-color: white;
  left: 0;
  top: 0;
  z-index: -1;
  transition: 0.3s ease-in-out;
}
.navbar-text button:hover {
  color: #21222A;
}
.navbar-text button:hover::before {
	content: "";
	width: 100%;
	height: 100%;
	position: absolute;
}
nav.navbar .navbar-toggler:active,
nav.navbar .navbar-toggler:focus {
	outline: none;
    box-shadow: none;
}
nav.navbar .navbar-toggler-icon {
  width: 24px;
  height: 17px;
  background-image: none;
  position: relative;
  border-bottom: 2px solid white;
  transition: all 300ms linear;
  top: -2px;
}
nav.navbar .navbar-toggler-icon:focus {
	border-bottom: 2px solid white;
}
nav.navbar .navbar-toggler-icon:after,
nav.navbar .navbar-toggler-icon:before {
	width: 24px;
	position: absolute;
	height: 2px;
	background-color: white;
	top: 0;
	left: 0;
	content: '';
	z-index: 2;
	transition: all 300ms linear;
}
nav.navbar .navbar-toggler-icon:after {
	top: 8px;
}
nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:after {
	transform: rotate(45deg);
	background-color: white;
	height: 2px;
}
nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:before {
	transform: translateY(8px) rotate(-45deg);
	background-color: white;
	height: 2px;
}
nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon {
	border-color: transparent;
}

/* About */
.about {
  width: 100%;
  margin-top: 0;
  padding: 260px 0 100px 0;
  background-image: none;
  background-position: top center;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #21222A;
  color: white;
}
.about .tagline {
  font-weight: 700;
  letter-spacing: 0.8px;
  padding: 8px 10px;
  background: linear-gradient(90.21deg, rgba(170, 54, 124, 0.5) -5.91%, rgba(74, 47, 189, 0.5) 111.58%);
  border: 1px solid rgba(255, 255, 255, 0.5);
  font-size: 20px;
  margin-bottom: 16px;
  display: inline-block;
}
.about h1 {
  font-size: 65px;
  font-weight: 700;
  letter-spacing: 0.8px;
  line-height: 1;
  margin-bottom: 20px;
  display: block;
}
.about p {
  color: #B8B8B8;
  font-size: 18px;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  width: 96%;
}
.about button {
  color: white;
  font-weight: 700;
  font-size: 20px;
  margin-top: 60px;
  letter-spacing: 0.8px;
  display: flex;
  align-items: center;
}
.about button svg {
  font-size: 25px;
  margin-left: 10px;
  transition: 0.3s ease-in-out;
  line-height: 1;
}
.about button:hover svg {
  margin-left: 25px;
}
.about img {
  animation: updown 3s linear infinite;
}
@keyframes updown {
    0% {
        transform: translateY(-20px);
    }
    50% {
        transform: translateY(20px);
    }
    100% {
        transform: translateY(-20px);
    }
}
.txt-rotate > .wrap {
  border-right: 0.08em solid #666;
}

/* Skills */
.skill {
    width: 100%;
    padding: 0;
    position: relative;
    color: white;
    background-color: #21222A;
}
.skill-bx {
    background: #21222A;
    text-align: center;
    padding: 60px 50px;
    margin-top: -60px;
}

.skill h2 {
    font-size: 45px;
    font-weight: 700;
    color: white;
    text-align: center;
}

.skill p {
    color: white;
    font-size: 18px;
    letter-spacing: -0.05em;
    line-height: 1.5em;
    margin: 14px 0 75px 0;
}
.skill-slider {
    width: 80%;
    margin: auto;
    position: relative;
}
.skill-slider .item img {
    width: 50%;
    margin: 0 auto 15px auto;
}
.background-image-left {
    top: 28%;
    position: absolute;
    bottom: 0;
    width: 40%;
    z-index: -4;
}

/* Projects */
.project {
  width: 100%;
  padding: 80px 0;
  position: relative;
  background-color: #21222A;
  color: white;
}
.project h2 {
	font-size: 45px;
    font-weight: 700;
    text-align: center;
}
.project p {
  color: white;
  font-size: 18px;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  margin: 14px auto 30px auto;
  text-align: center;
  width: 56%;
}
.project .nav.nav-pills {
  width: 50%;
  margin: 0 auto;
  border-radius: 100px;
  background-color: rgb(255 255 255 / 10%);
  overflow: hidden;
}
.project .nav.nav-pills .nav-item {
  width: 100%;
  border-radius: 50px;
}
.project .nav.nav-pills .nav-link {
  background-color: transparent;
  border-radius: 50px;
  padding: 17px 0;
  color: white;
  width: 100%;
  font-size: 17px;
  letter-spacing: -0.05px;
  font-weight: 500;
  position: relative;
  transition: 0.3s ease-in-out;
  text-align: center;
  z-index: 0;
}
.project .nav.nav-pills .nav-link::before {
    content: "";
    position: absolute;
    width: 0;
    height: 100%;
    background: linear-gradient(90.21deg, #AA367C -5.91%, #4A2FBD 111.58%);
    top: 0;
    left: 0;
    z-index: -1;
    transition: 0.3s ease-in-out;
    border-radius: 50px;
}
.project .nav.nav-pills .nav-link.active::before {
    width: 100%;
}
.project .nav.nav-pills .nav-link.active {
    border: 1px solid rgba(255, 255, 255, 1);
}
.project-imgbx {
  position: relative;
  border-radius: 30px;
  overflow: hidden;
  margin-bottom: 24px;
}
.project-imgbx::before {
  content: "";
  background: linear-gradient(90.21deg, #AA367C -5.91%, #4A2FBD 111.58%);
  opacity: 0.85;
  position: absolute;
  width: 100%;
  height: 0;
  transition: 0.4s ease-in-out;
}
.project-imgbx:hover::before {
  height: 100%;
}
.project-text {
  position: absolute;
  text-align: center;
  top: 65%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: 0.5s ease-in-out;
  opacity: 0;
  width: 100%;
}
.project--title {
    color: white;
}

.project-imgbx:hover .project-text {
  top: 50%;
  opacity: 1;
}
.project-text h4 {
  font-size: 30px;
  font-weight: 700;
  letter-spacing: 0.8px;
  line-height: 1.1em;
}
.project-text span {
  font-style: italic;
  font-weight: 400;
  font-size: 15px;
  letter-spacing: 0.8px;
}
.project-card {
    max-width: 50%;
    max-height: 50%
}

/* Publication */
.publication {
  width: 100%;
  padding: 80px 0;
  position: relative;
  background-color: #21222A;
  color: white;
}
.publication h2 {
	font-size: 45px;
    font-weight: 700;
    text-align: center;
}
.publication p {
  color: white;
  font-size: 18px;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  margin: 14px auto 30px auto;
  text-align: center;
  width: 56%;
}
.publication .nav.nav-pills {
  width: 50%;
  margin: 0 auto;
  border-radius: 100px;
  background-color: rgb(255 255 255 / 10%);
  overflow: hidden;
}
.publication .nav.nav-pills .nav-item {
  width: 100%;
  border-radius: 50px;
}
.publication .nav.nav-pills .nav-link {
  background-color: transparent;
  border-radius: 50px;
  padding: 17px 0;
  color: white;
  width: 100%;
  font-size: 17px;
  letter-spacing: -0.05px;
  font-weight: 500;
  position: relative;
  transition: 0.3s ease-in-out;
  text-align: center;
  z-index: 0;
}
.publication .nav.nav-pills .nav-link::before {
    content: "";
    position: absolute;
    width: 0;
    height: 100%;
    background: linear-gradient(90.21deg, #AA367C -5.91%, #4A2FBD 111.58%);
    top: 0;
    left: 0;
    z-index: -1;
    transition: 0.3s ease-in-out;
    border-radius: 50px;
}
.publication .nav.nav-pills .nav-link.active::before {
    width: 100%;
}
.publication .nav.nav-pills .nav-link.active {
    border: 1px solid rgba(255, 255, 255, 1);
}
.publication-imgbx {
  position: relative;
  border-radius: 30px;
  overflow: hidden;
  margin-bottom: 24px;
}
.publication-imgbx::before {
  content: "";
  background: linear-gradient(90.21deg, #AA367C -5.91%, #4A2FBD 111.58%);
  opacity: 0.85;
  position: absolute;
  width: 100%;
  height: 0;
  transition: 0.4s ease-in-out;
}
.publication-imgbx:hover::before {
  height: 100%;
}
.publication-text {
  position: absolute;
  text-align: center;
  top: 65%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: 0.5s ease-in-out;
  opacity: 0;
  width: 100%;
}
.publication--title {
    color: white;
}

.publication-imgbx:hover .publication-text {
  top: 50%;
  opacity: 1;
}
.publication-text h4 {
  font-size: 20px;
  font-weight: 700;
  letter-spacing: 0.8px;
  line-height: 1.1em;
}
.publication-text span {
  font-style: italic;
  font-weight: 400;
  font-size: 15px;
  letter-spacing: 0.8px;
}
.publication-card {
    max-width: 50%;
    max-height: 50%
}

.publication--row {
    justify-content: center;
}

/* Contact */
.contact {
    background-color: #21222A;
    color: white;
    padding: 60px 0 100px 0;

}
.contact h2 {
	font-size: 45px;
	font-weight: 700;
    margin-bottom: 30px;
}
.contact form input, .contact form textarea {
  width: 100%;
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 20px;
  color: white;
  margin: 0 0 8px 0;
  padding: 10px 26px;
  font-weight: 500;
  font-size: 18px;
  letter-spacing: 0.8px;
  transition: 0.3s ease-in-out;
}
.contact form input:focus, .contact form textarea:focus {
  background: rgba(255, 255, 255, 1);
  color: #121212;
}
.contact form input::placeholder, .contact form textarea::placeholder {
  font-size: 16px;
  font-weight: 400;
  color: white;
}
.contact form input:focus::placeholder, .contact form textarea:focus::placeholder {
  color: #121212;
  opacity: 0.8;
}
.contact form button {
  font-weight: 700;
  color: black;
  background-color: white;
  padding: 10px 48px;
  font-size: 18px;
  margin-top: 25px;
  border-radius: 20px;
  position: relative;
  transition: 0.3s ease-in-out;
}
.contact form button span {
  z-index: 1;
  position: relative;
  border-radius: 20px;
}
.contact form button:hover {
  color: white;
}
.contact form button::before {
  content: "";
  background: #121212;
  width: 0;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  transition: 0.3s ease-in-out;
  border-radius: 20px;
}
.contact form button:hover::before {
  width: 100%;
}

/* Footer */
.footer {
  width: 100%;
  padding: 0 0 50px 0;
  background-image: none;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #21222A;
}
.footer p {
  font-weight: 400;
  font-size: 14px;
  color: white;
  letter-spacing: 0.5px;
  margin-top: 20px;
}